<template>
  <v-card dark :color="darkTheme ? null : '#2D88FF'" elevation="6" class="twitter-card">
    <div class="twitter-card-icon-background"><v-icon>mdi-facebook</v-icon></div>
    <v-card-title class="pt-6 pb-0 pl-7 my-0 text-body-1 align-center">
      Crear nueva publicación
      <v-spacer></v-spacer>
      <v-btn icon @click="sendClose()"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>

    <v-card-actions class="pt-0 my-0">
      <v-container fluid>
        <v-form class="mb-4 w-100">
          <v-row>
            <v-col cols="12" class="pb-0 mb-0">
              <v-textarea filled label="Post" v-model="reply" rows="6" dense></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end pt-0 mt-0">
              <v-btn
                color="primary"
                @click="confirmDialog = true"
                :loading="isLoading"
                :disabled="isLoading"
                >Publicar</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-actions>
    <v-dialog v-model="confirmDialog" width="400px">
      <v-card>
        <v-card-title class="text-center">
          <h5>¿Seguro que desea publicar?</h5>
        </v-card-title>
        <v-card-actions class="d-flex justify-end">
          <v-btn small color="secondary" @click="confirmDialog = false">Cancelar</v-btn>
          <v-btn
            small
            color="primary"
            @click="createPost()"
            :loading="isLoading"
            :disabled="isLoading"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      reply: "",
      buttonLoading: false,
      confirmDialog: false,
    };
  },
  methods: {
    sendClose() {
      this.reply = "";
      this.$emit("closeDialog");
    },
    sendRefresh() {
      this.reply = "";
      this.$emit("closeDialogRefresh");
    },
    createPost() {
      this.buttonLoading = true;
      this.confirmDialog = false;
      if (this.reply.length == 0) {
        this.$store.commit("showSnackBar", {
          text: "No hay post para enviar",
          color: "warning",
          icon: "mdi-alert",
        });
        this.buttonLoading = false;
        this.sendClose();
        return;
      }
      let data = new FormData();

      data.append("message", this.reply);

      axios
        .post("public/createpost", data)
        .then((response) => {
          this.buttonLoading = false;
          this.$store.commit("showSnackBar", {
            text:
              typeof response.data === "object" && "message" in response.data
                ? response.data.message
                : response.data,
            color: "success",
            icon: "mdi-check-circle",
          });
          this.sendRefresh();
        })
        .catch(() => {
          // console.log(error.response.data);
          this.buttonLoading = false;
          this.sendClose();
        });
    },
  },
  computed: {
    ...mapState(["darkTheme"]),
    isLoading() {
      return this.buttonLoading;
    },
  },
};
</script>
