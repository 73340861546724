<template>
  <div>
    <v-tabs v-model="facebookTab" align-with-title class="bg-transparent">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab><v-icon class="mr-2">mdi-facebook</v-icon> Publicaciones pendientes</v-tab>
      <v-tab><v-icon class="mr-2">mdi-facebook</v-icon> Mis publicaciones</v-tab>
    </v-tabs>
    <v-tabs-items v-model="facebookTab" class="bg-transparent">
      <v-tab-item>
        <PendientesFB />
      </v-tab-item>
      <v-tab-item>
        <PostsFB />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PendientesFB from "@/components/PendientesFB";
import PostsFB from "@/components/PostsFB";

export default {
  components: {
    PendientesFB,
    PostsFB
  },
  data() {
    return {
      facebookTab: null,
    };
  },
};
</script>