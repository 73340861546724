<template>
  <v-container v-if="mounted">
    <!-- <v-btn color="primary" @click="test">test</v-btn> -->
    <v-row class="mt-6" v-scrollfadein>
      <v-col><h1>Publicaciones pendientes</h1></v-col>
      <v-col cols="12" sm="6" md="3" class="d-flex justify-end">
        <v-btn color="primary" @click="postCard = true">
          <v-icon left> mdi-facebook </v-icon>
          Nueva publicación
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center mb-6" v-scrollfadein>
      <v-col cols="12" class="pb-0"><h3>Filtros</h3></v-col>
      <v-col cols="12" sm="6" md="3" class="pb-0">
        <v-menu
          ref="menu"
          v-model="dateSelected"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Rango de fechas"
              hint="Selecciona el rango de fechas y haz clic en OK"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              v-model="dateRangeText"
              clearable
              @click:clear="clearArray"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateSelected = false"> Cancelar </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="pb-0">
        <v-select
          prepend-icon="mdi-filter-variant"
          v-model="statusValue"
          :items="status"
          label="Estatus"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" class="pb-0">
        <!-- <v-col cols="12" class="mt-0 pt-0 mb-6"> -->
        <v-btn color="primary" @click="getFBPosts(false)" class="mr-2">
          <v-icon>mdi-filter</v-icon>Filtrar
        </v-btn>
        <v-btn color="primary" @click="genCsv()" :disabled="csvButtonDisabled">
          <v-icon>mdi-download</v-icon>Descargar
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="posts">
      <v-col cols="12" sm="6" md="4" xl="3" v-for="(item, index) in posts" :key="index">
        <FacebookCard
          :fb_post="item"
          small
          @openDialog="showCardDialog(item)"
          v-scrollfadein
        />
      </v-col>
    </v-row>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      :duration="300"
    >
      <v-row v-if="squeletonLoader">
        <v-col cols="12" sm="6" md="4" xl="3" v-for="n in 12" :key="n">
          <v-skeleton-loader
            type="avatar, paragraph"
            max-height="200"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </transition>
    <v-dialog v-model="replyCard" width="700">
      <FacebookReplyCard
        :fb_post="replyCardInfo"
        :status_list="status"
        :key="replyCardInfo ? replyCardInfo.id_dato : 0"
        @closeDialog="replyCard = false"
        @closeDialogRefresh="getFBPosts(false)"
      />
    </v-dialog>
    <v-dialog v-model="postCard" width="700">
      <FacebookPostCard @closeDialog="postCard = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import FacebookCard from "@/components/FacebookCard";
import FacebookReplyCard from "@/components/FacebookReplyCard";
import FacebookPostCard from "@/components/FacebookPostCard";
// import dateformat from "dateformat";
import { json2excel } from "js2excel";
// import fbdata from "@/data/Facebook";
import axios from "axios";

export default {
  components: {
    FacebookCard,
    FacebookReplyCard,
    FacebookPostCard
  },
  data() {
    return {
      mounted: false,
      postCard: false,
      replyCard: false,
      replyCardInfo: null,
      posts: null,
      dates: [],
      dateSelected: false,
      status: [
        { text: "Activo", value: 1 },
        { text: "Inactivo", value: 2 },
        { text: "Lectura", value: 3 },
        { text: "Escritura", value: 4 },
        { text: "Respondido", value: 5 },
        { text: "Atendido sin respuesta", value: 6 },
        { text: "Eliminado", value: 7 },
      ],
      statusValue: null,
      showSqueleton: false,
    };
  },
  methods: {
    test() {
      console.log(JSON.stringify(this.dateRange), JSON.stringify(this.statusValue));
    },
    setDates(evt) {
      console.log(evt);
    },
    showCardDialog(card) {
      this.replyCard = true;
      this.replyCardInfo = card;
    },
    getFBPosts(showOverlay) {
      this.replyCard = false;
      this.postCard = false;
      if (!showOverlay) {
        this.showSqueleton = true;
      }
      this.posts = null;
      let data = new FormData();

      if (this.dates.length == 2) {
        data.append("date_start", this.dateRange[0] + " 00:00:00");
        data.append("date_end", this.dateRange[1] + " 23:59:59");
      }

      this.statusValue
        ? data.append("status", this.statusValue)
        : data.append("status", 1);

      // this.posts = fbdata;

      axios
        .post("public/comments", data, { config: { showOverlay } })
        .then((response) => {
          this.showSqueleton = false;
          this.posts = response.data.data;
        })
        .catch(() => {
          this.showSqueleton = false;
          // console.log(error.response.data);
        });
    },
    clearArray() {
      this.dateRange = [];
    },
    sortArray(array) {
      return array.sort();
    },
    genCsv() {
      // console.log(this.posts);
      try {
        json2excel({
          data: this.posts,
          name: "posts",
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    squeletonLoader() {
      return this.showSqueleton;
    },
    csvButtonDisabled() {
      return this.posts == null || this.posts.length == 0;
    },
    dateRange: {
      get: function () {
        return this.sortArray(this.dates);
      },
      set: function (array) {
        this.dates = array;
      },
    },
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (text) {
        return text;
      },
    },
  },
  mounted() {
    // let currDate = dateformat(new Date(), "yyyy-mm-dd");
    // this.dates = [currDate, currDate];
    this.getFBPosts(true);
    this.mounted = true;
  },
};
</script>
