<template>
  <v-card dark :color="darkTheme ? null : '#2D88FF'" elevation="6" class="twitter-card">
    <div class="twitter-card-icon-background"><v-icon>mdi-facebook</v-icon></div>
    <v-sheet
      :color="darkTheme ? null : '#2D88FF'"
      :class="
        dialog ? 'rounded-circle twitter-avatar-dialog' : 'rounded-circle twitter-avatar'
      "
    >
      <v-avatar size="65" class="ma-1">
        <v-img :alt="fb_post.nombre" :src="fb_post.imagen"></v-img>
      </v-avatar>
    </v-sheet>
    <v-card-title class="pt-4 pb-0 my-0 text-body-1">
      <span :class="small ? 'twitter-card-title-truncate' : 'twitter-card-title '">
        {{ fb_post.nombre }}
      </span>
    </v-card-title>
    <v-card-subtitle class="pa-0 pb-2 my-0 ml-4 text-caption">
      <span class="twitter-card-subtitle">
        {{ fb_post.fecha_creacion }}
      </span>
    </v-card-subtitle>

    <v-card-text :class="dialog ? 'pt-3' : null" :style="small ? 'height: 85px' : null">
      <span :class="small ? 'twitter-card-text-truncate' : 'twitter-card-text'">
        {{ fb_post.mensaje }}
      </span>
    </v-card-text>

    <v-card-actions class="py-0 my-0">
      <v-list-item>
        <v-row align="center">
          <v-chip small color="primary"> {{ statusFBPost }} </v-chip>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="$emit('openDialog')">
            <v-icon small class="mr-2">{{
              this.fb_post.id_status != 1 ? "mdi-eye" : "mdi-comment-text"
            }}</v-icon>
            {{ this.fb_post.id_status != 1 ? "Ver" : "Responder" }}
          </v-btn>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    fb_post: { type: Object, required: true },
    small: { type: Boolean, default: false },
    dialog: { type: Boolean, default: false },
  },
  methods: {
    sendClose() {
      this.$emit("hide", true);
    },
  },
  computed: {
    ...mapState(["darkTheme"]),
    statusFBPost() {
      let status = null;
      switch (this.fb_post.id_status) {
        case "1":
          status = "Activo";
          break;
        case "2":
          status = "Inactivo";
          break;
        case "3":
          status = "Lectura";
          break;
        case "4":
          status = "Escritura";
          break;
        case "5":
          status = "Respondido";
          break;
        case "6":
          status = "Atendido sin respuesta";
          break;
        case "7":
          status = "Eliminado";
          break;
      }
      return status;
    },
  },
};
</script>
