<template>
  <v-container v-if="mounted">
    <!-- <v-btn color="primary" @click="test">test</v-btn> -->
    <v-row class="mt-6 mb-6" v-scrollfadein>
      <v-col><h1>Mis publicaciones</h1></v-col>
      <v-col cols="12" sm="6" md="3" class="d-flex justify-end">
        <v-btn color="primary" @click="postCard = true">
          <v-icon left> mdi-facebook </v-icon>
          Nueva publicación
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="posts">
      <v-col cols="12" sm="6" md="4" xl="3" v-for="(item, index) in posts" :key="index">
        <FacebookMyPostCard
          :fb_post="item"
          small
          @openDialog="showCardDialog(item)"
          v-scrollfadein
        />
      </v-col>
    </v-row>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      :duration="300"
    >
      <v-row v-if="squeletonLoader">
        <v-col cols="12" sm="6" md="4" xl="3" v-for="n in 12" :key="n">
          <v-skeleton-loader
            type="avatar, paragraph"
            max-height="200"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </transition>
    <v-dialog v-model="replyCard" width="700">
      <FacebookMyPostCard
        :fb_post="replyCardInfo"
        :small="false"
        @closeDialog="replyCard = false"
        @closeDialogRefresh="getMyFBPosts(false)"
        :key="replyCardInfo.id"
        v-if="replyCardInfo"
      />
    </v-dialog>
    <v-dialog v-model="postCard" width="700">
      <FacebookPostCard
        @closeDialog="postCard = false"
        @closeDialogRefresh="getMyFBPosts(false)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import FacebookMyPostCard from "@/components/FacebookMyPostCard";
import FacebookPostCard from "@/components/FacebookPostCard";
// import dateformat from "dateformat";
import { json2excel } from "js2excel";
// import fbdata from "@/data/Facebook";
import axios from "axios";

export default {
  components: {
    FacebookMyPostCard,
    FacebookPostCard,
  },
  data() {
    return {
      mounted: false,
      postCard: false,
      replyCard: false,
      replyCardInfo: null,
      posts: null,
      dates: [],
      dateSelected: false,
      status: [
        { text: "Activo", value: 1 },
        { text: "Inactivo", value: 2 },
        { text: "Lectura", value: 3 },
        { text: "Escritura", value: 4 },
        { text: "Respondido", value: 5 },
        { text: "Atendido sin respuesta", value: 6 },
        { text: "Eliminado", value: 7 },
      ],
      statusValue: null,
      showSqueleton: false,
    };
  },
  methods: {
    test() {
      console.log(JSON.stringify(this.dateRange), JSON.stringify(this.statusValue));
    },
    setDates(evt) {
      console.log(evt);
    },
    showCardDialog(card) {
      this.replyCard = true;
      this.replyCardInfo = card;
    },
    getMyFBPosts(showOverlay) {
      this.replyCard = false;
      this.postCard = false;
      if (!showOverlay) {
        this.showSqueleton = true;
      }
      this.posts = null;

      axios
        .get("public/searchpost", { config: { showOverlay } })
        .then((response) => {
          this.showSqueleton = false;
          this.posts = response.data;
        })
        .catch((error) => {
          this.showSqueleton = false;
          console.log(error.response.data);
        });
    },
    clearArray() {
      this.dateRange = [];
    },
    sortArray(array) {
      return array.sort();
    },
    genCsv() {
      // console.log(this.posts);
      try {
        json2excel({
          data: this.posts,
          name: "posts",
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    squeletonLoader() {
      return this.showSqueleton;
    },
    csvButtonDisabled() {
      return this.posts == null || this.posts.length == 0;
    },
    dateRange: {
      get: function () {
        return this.sortArray(this.dates);
      },
      set: function (array) {
        this.dates = array;
      },
    },
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (text) {
        return text;
      },
    },
  },
  mounted() {
    this.getMyFBPosts(true);
    this.mounted = true;
  },
};
</script>
